<template>
	<div class="container">

		<div class="citypicshangwu">
			<div class="citypic">
				<blue-title>峰会头条</blue-title>
				<div class="listone ">
					<div :class="{ 'listitem': true, 'page1': true }" v-for="item in news" @click="toDetail(item.id)"
						:key="item.id">
						<div class="coverbox">

							<img class="cover" :src="item.cover_url" alt="">
						</div>
						<div class="content">
							<div class="topcon">
								<div class="title">{{ item.title }}</div>
								<div class="summary">{{ item.summary }}</div>
							</div>
							<div class="create_at"  style="color:#A0A0A0;font-size:12px"  >发布时间：{{ item.times }}</div>
						</div>

					</div>
					<!-- <div class="page">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
							:page-size="size" background layout="prev, pager, next" :total="total">
						</el-pagination>
					</div> -->
				</div>
			</div>

			<div class="shangwu">
				<hezuo :hezuo="cooperation"></hezuo>
			</div>
		</div>

		<div class="licheng">
			<licheng name="峰会历程" :licheng="licheng" @changeYear="changeYear"></licheng>
		</div>



	</div>
</template>
<script>
import BlueTitle from '@/components/website/BlueTitle'
import { fenghuiDetail, fenghuiLichengDetail } from '@/api/website/zhanting.js'
import Licheng from '@/components/website/Licheng.vue'
import Hezuo from '@/components/website/Hezuo.vue'
export default {
	name: 'PcWebsitefenghui',
	components: {
		BlueTitle,
		Licheng,
		Hezuo
	},
	data() {
		return {
			summaryHide: false,
			video: {}, //视频

			news: [], //新闻

			licheng: {},
			year: [],//历程年
			lichengpic: [],//历程图片
			join_city_pic_url: '',
			cooperation: [],

		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	mounted() {
		this.init()
	},

	methods: {
		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/fenghui/' + id
			// this.$router.push(tourl)
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},


		changeYear(year) {
			this.year.map(item => {
				if (item.year === year) {
					item.checked = true
				} else {
					item.checked = false
				}
			})

			//获取当前年
			fenghuiLichengDetail({ year: year }).then(res => {
				this.lichengpic = res.data.list
				this.licheng = {
					year: this.year,
					pics: res.data.list,
					course_year: year,
					title: res.data.title

				}
			})
		},

		init() {
			fenghuiDetail().then(res => {
				this.licheng = res.data.course
				this.news = res.data.news
				this.year = res.data.course.year
				this.lichengpic = res.data.course.pics
				this.cooperation = res.data.cooperation
				this.join_city_pic_url = res.data.join_city_pic_url

				// this.initVideo()
			})

		},

		// 鼠标移入
		onmouseenter() {
			this.summaryHide = true
		},
		// 鼠标移出
		mouseleave() {
			this.summaryHide = false
		},



	},
};
</script>

<style lang="scss" scoped>
.citypicshangwu {
	height: 795px;
	width: 1200px;
	background: '#ffffff';
	// margin-top: 20px;
	display: flex;
	justify-content: space-between;

	.citypic {
		width: 830px;
		height: 792px;
		background: #FFFFFF;

	}



}


.shangwu {
	width: 356px;
	height: 795px;
	
	background: #FFFFFF;

}


.container {
	width: 1200px;
	margin: 0 auto;
	background: #F0F3F8;
}


.news {
	width: 356px;
	height: 536px;
	background: #ffffff;

	.tuwen {
		cursor: pointer;
		height: 70px;
		width: 336px;
		display: flex;
		margin: 0px auto;
		padding: 10px 0px;
		border-bottom: solid 1px #F2F2F2;

		.tu {
			width: 70px;
			height: 70px;
			// overflow: hidden;

			.pic {
				width: 70px;
				height: 70px;
				object-fit: cover;
			}

		}

		.wen {
			width: 256px;
			height: 70px;
			padding-left: 10px;

			// display: flex;
			// flex-direction: column;
			// justify-content: space-between;
			// align-items: flex-start;
			.titlebox {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: flex-start;


				.title {
					display: inline-block;
					height: 46px;
					text-align: left;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #000000;
					line-height: 23px;

					text-overflow: -o-ellipsis-lastline;
					overflow: hidden; //溢出内容隐藏
					text-overflow: ellipsis; //文本溢出部分用省略号表示
					display: -webkit-box; //特别显示模式
					-webkit-line-clamp: 2; //行数
					line-clamp: 2;
					-webkit-box-orient: vertical; //盒子中内容竖直排列



				}

				.showdate {
					height: 17px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #A0A0A0;
					line-height: 17px;
					text-align: left;
				}
			}


		}
	}

	.tuwen:first-child {
		padding-top: 0;
	}

	.tuwen:last-child {
		border-bottom: none;
	}

}

.licheng {
	width: 1200px;
	height: 497px;
	background: #FFFFFF;
	margin-top: 20px;
}


.page {
	margin-top: 30px;
	margin-bottom: 30px;
}

.listone {
	height: auto;
	width: 830px;
	padding: 0 20px;

	.listitem {
		cursor: pointer;
		width: 790px;
		height: 160px;
		display: flex;
		align-items: center;
		border-bottom: solid 1px #F2F2F2;

		.coverbox {
			width: 180px;
			height: 120px;

			.cover {
				width: 180px;
				height: 120px;
				object-fit: cover;
			}
		}


		.content {
			width: 600px;
			height: 120px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			margin-left: 10px;

			.topcon {
				width: 600px;

				.title {
					text-align: left;
					height: 28px;
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #000000;
					line-height: 28px;
					overflow: hidden; //超出隐藏
					white-space: nowrap; //不折行
					text-overflow: ellipsis; //溢出显示省略号

				}


				.summary {
					text-align: left;
					height: 40px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #848484;
					line-height: 20px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden; //溢出内容隐藏
					text-overflow: ellipsis; //文本溢出部分用省略号表示
					display: -webkit-box; //特别显示模式
					-webkit-line-clamp: 2; //行数
					line-clamp: 2;
					-webkit-box-orient: vertical; //盒子中内容竖直排列
				}

			}
		}


	}

	.page1:first-child {
		width: 790px;
		height: 232px;
		display: flex;
		align-items: center;
		border-bottom: solid 1px #F2F2F2;

		.coverbox {
			width: 320px;
			height: 192px;

			.cover {
				width: 320px;
				height: 192px;
				object-fit: cover;
			}
		}


		.content {
			width: 454px;
			height: 192px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			margin-left: 10px;

			.topcon {
				width: 454px;

				.title {
					text-align: left;
					height: 28px;
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #000000;
					line-height: 28px;
					overflow: hidden; //超出隐藏
					white-space: nowrap; //不折行
					text-overflow: ellipsis; //溢出显示省略号

				}


				.summary {
					text-align: left;
					height: 40px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #848484;
					line-height: 20px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden; //溢出内容隐藏
					text-overflow: ellipsis; //文本溢出部分用省略号表示
					display: -webkit-box; //特别显示模式
					-webkit-line-clamp: 6; //行数
					line-clamp: 6;
					-webkit-box-orient: vertical; //盒子中内容竖直排列
				}

			}
		}


	}


}

</style>